import dayjs from "dayjs";

export const getFormatedDate = (date) => {
  const d = !date ? new Date() : date;
  return dayjs(d).format("DD/MM/YYYY");
};

export const getFormatedDateWithHours = (date) => {
  const d = !date ? new Date() : date;
  return dayjs(d).format("DD/MM/YYYY - HH:mm");
};

export const getDateFromFormat = (date) => {
  if (!date) return new Date();

  const [d, m, y] = date?.split("/");
  let formatedDate = new Date();

  formatedDate.setDate(Number(d));
  formatedDate.setMonth(Number(m) - 1);
  formatedDate.setFullYear(Number(y));

  return formatedDate;
};

export const getFormatedDateFromInput = (date) => {

  if (!date) return new Date().getTime();

  const [y, m, d] = date.split("-");
  
  const formatedDate = new Date(Number(y), Number(m) - 1, Number(d));


  return formatedDate.getTime();
};

export const getDateForInputDate = (date) => {
  const d = !date ? getFormatedDate() : getFormatedDate(date);
  return d.split("/").reverse().join("-");
};
